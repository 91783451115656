<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button type="primary" icon="el-icon-plus" size="small" @click="addRootDept">添加</el-button>
    <div class="title"></div>
    <a-divider />

    <div class="treeArea">
      <div class="tree">
        <el-tree
          highlight-current
          :data="treeData"
          :props="defaultProps"
          :expand-on-click-node="false"
          :default-expand-all="true"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="line" v-show="level"></div>
      <div class="content" v-show="level">
        <div class="item">{{ currentDept.deptName }}</div>
        <div class="item">
          <el-link type="primary" :underline="false" @click="editDept">编辑</el-link>

          <el-popconfirm :title="`确定删除部门【${currentDept.deptName}】吗`" @confirm="removeDept">
            <el-link type="primary" :underline="false" slot="reference">删除</el-link>
          </el-popconfirm>
          <el-link type="primary" :underline="false" @click="addDept">新增子部门</el-link>
          <!-- <el-link type="primary" :underline="false" v-show="level != 1">排序向上</el-link>
          <el-link type="primary" :underline="false" v-show="level != 1">排序向下</el-link> -->
        </div>
      </div>
      <!-- 弹窗 -->
      <el-dialog :title="title" :visible.sync="dialogVisible" width="600px" :show-close="false">
        <el-form ref="form" :model="formData" label-width="120px" :rules="rules">
          <el-form-item label="当前的部门为：" v-show="formData.parentId != 0">
            <span style="font-weight:700">{{ currentDept.deptName }}</span>
          </el-form-item>
          <el-form-item label="部门名称" prop="deptName">
            <el-input v-model="formData.deptName" placeholder="请输入" style="width:215px"></el-input>
          </el-form-item>
          <el-form-item label="部门类型" prop="deptType">
            <el-select v-model="formData.deptType" placeholder="请选择">
              <el-option v-for="item in deptType" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="formData.sort" placeholder="排序" style="width:215px"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" style="text-align:center">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getDeptTree, removeDept, addDept, editDept, getDeptDetail } from './api'
import { dictConfigs, DEPT_TYPE, ARTICLE_TYPE as TYPE, COVER_SIZE as SIZE } from '@/enum/dict.js'
export default {
  name: 'Index',

  data() {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'deptName'
      },
      formData: {
        deptName: '',
        deptType: null,
        parentId: null,
        sort: 0
      },
      rules: {
        deptName: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        deptType: [{ required: true, message: '该项不能为空', trigger: 'blur' }]
      },
      level: '', //菜单等级
      currentDept: {}, //树形的每项数据
      dialogVisible: false,
      title: '',
      // 字典
      DEPT_TYPE,
      deptType: [],
      TYPE,
      SIZE
    }
  },

  mounted() {
    this.getDeptTree()
    this.dictConfigs()
  },

  methods: {
    async dictConfigs() {
      this.deptType = await dictConfigs(DEPT_TYPE)
    },
    async getDeptTree() {
      const res = await getDeptTree()
      this.treeData = res || []
    },
    handleNodeClick(data, node) {
      this.level = node.level
      this.currentDept = data
    },
    addDept() {
      this.title = '新增子部门'
      this.dialogVisible = true
      this.initFormData()
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.formData.parentId = this.currentDept.deptId
    },
    addRootDept() {
      this.title = '新增部门'
      this.dialogVisible = true
      this.initFormData()
      this.formData.parentId = 0
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    async removeDept() {
      let deptIds = []
      deptIds.push(this.currentDept.deptId)
      await removeDept(deptIds).then(() => {
        this.$message.success('删除成功')
        this.getDeptTree()
        this.level = ''
        this.currentDept = {}
      })
    },
    async editDept() {
      this.title = '编辑栏目'
      this.dialogVisible = true
      this.getDeptDetail()
    },
    async getDeptDetail() {
      const deptId = this.currentDept.deptId
      const res = await getDeptDetail(deptId)
      this.formData = res
      this.formData.deptType = this.formData.deptType.toString()
    },
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.deptId) {
            // 编辑
            await editDept(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getDeptTree()
              this.dialogVisible = false
              this.level = ''
              this.currentDept = {}
            })
          } else {
            // 新增

            await addDept(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getDeptTree()
              this.dialogVisible = false
              this.level = ''
              this.currentDept = {}
            })
          }
        }
      })
    },
    cancel() {
      this.dialogVisible = false
    },
    initFormData() {
      this.formData = {
        deptName: '',
        deptType: null,
        parentId: null,
        sort: 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .treeArea {
    display: flex;
    justify-content: start;
    .tree {
      width: 300px;
      .el-tree /deep/ {
        .el-tree-node__content {
          height: 40px;
          .el-tree-node__expand-icon {
            margin-left: 20px;
          }
        }
        .el-tree-node.is-current > .el-tree-node__content {
          background-color: #e6f7ff;
          border-right: 3px solid #1890ff;
          .el-tree-node__label,
          .el-tree-node__expand-icon {
            color: #1890ff;
          }
          .el-tree-node__expand-icon.is-leaf {
            color: transparent !important;
          }
        }
      }
    }
    .line {
      margin-right: 100px;
      width: 1px;
      background-color: #ccc;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: start;
      height: 300px;
      width: 600px;
      .item {
        margin-bottom: 50px;
      }
      .item:nth-child(1) {
        text-align: center;
        font-weight: 700;
        font-size: 30px;
      }
      .item:nth-child(2) {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
      }
      .item:nth-child(3) {
        display: flex;
        justify-content: space-between;
      }
      .item:nth-child(4) {
        display: flex;
        .order {
          display: flex;
          justify-content: start;
          height: 35px;
          margin-right: 80px;
          span {
            font-size: 16px;
            line-height: 35px;
            color: #66b1ff;
          }
          .el-button {
            border-radius: 0;
            height: 35px;
            background-color: #f5f7fa;
          }
          .btnL {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: 0;
          }
          .btnR {
            border-radius: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: 0;
          }
          .el-input {
            border-radius: 0;

            /deep/.el-input__inner {
              border-radius: 0;
              height: 35px;
              text-align: center;
              outline: none;
            }
          }
        }
      }
    }
    .el-form {
      padding-left: 90px;
    }
    /deep/.el-dialog__body {
      padding-top: 0;
    }
    /deep/.el-dialog__footer {
      padding-bottom: 50px;
    }
  }
}
</style>
